import React, { useContext } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import { ColorSchemeContext } from "../../../contexts/ColorScheme"
import morning from "./bg_header_morning.svg"
import day from "./bg_header_day.svg"
import evening from "./bg_header_evening.svg"
import night from "./bg_header_night.svg"
import PageBackground from "../../PageBackground"
import { getIfColorIsDark } from "../../../utils/colors"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      position: "absolute",
      top: "7.8125rem",
      left: "50%",
      transform: "translateX(-50%)",
      opacity: "100%",
      height: "101.25rem",
      [up("xl")]: {
        height: "auto",
        width: "100%",
      },
    },
    darker: {
      opacity: 1,
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Background = ({ classes }: Props) => {
  const { colorScheme } = useContext(ColorSchemeContext)!

  const getBackground = (): string => {
    switch (colorScheme) {
      case "morning":
        return morning
      case "day":
        return day
      case "evening":
        return evening
      default:
        return night
    }
  }

  const backgroundSrc: string = getBackground()

  const getClasses = (): string => {
    const isDark: boolean = getIfColorIsDark(colorScheme)
    if (isDark) {
      return `${classes.root} ${classes.darker}`
    }
    return classes.root
  }

  const className: string = getClasses()

  return (
    <PageBackground
      className={className}
      src={backgroundSrc}
      alt="Hero background"
    />
  )
}

export default withStyles(styles)(Background)
