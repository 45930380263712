export const navDimensions = {
  height: "5.75rem",
  marginBottom: "4.4375rem",
  md: {
    height: "6.6875rem",
  },
  lg: {
    height: "13.6875rem",
  },
}

export const navWithLogoDimensions = {
  lg: {
    marginBottom: "10.1875rem",
  },
}
