import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import { closeDrawer } from "../../types"
import HamburgerButton from "../../HamburgerButton"

const styles = () => {
  return createStyles({})
}

interface Props extends WithStyles<typeof styles> {
  closeDrawer: closeDrawer
}

const CloseButton = ({ closeDrawer }: Props) => {
  return (
    <HamburgerButton
      className="hamburger--collapse is-active"
      onClick={closeDrawer}
    />
  )
}

export default withStyles(styles)(CloseButton)
