import React, { useContext } from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import { SvgPropsWithUniqueId } from "../../types/svg"
import Title from "./Title"
import logoDark from "./dark.svg"
import logoLight from "./light.svg"
import logoMorning from "./morning.svg"
import { ColorSchemeContext } from "../../contexts/ColorScheme"

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      width: "16.125rem",
    },
  })
}

export interface Props extends WithStyles<typeof styles> {
  idPrefix: SvgPropsWithUniqueId["idPrefix"]
  title?: React.ReactNode
}

const LogoAndText = ({ classes, title = <Title /> }: Props) => {
  const { colorScheme } = useContext(ColorSchemeContext)!

  const getImageSrc = (): string => {
    if (colorScheme === "morning") {
      return logoMorning
    } else if (colorScheme === "day") {
      return logoLight
    }
    return logoDark
  }

  const imgSrc: string = getImageSrc()

  return (
    <div className={classes.root}>
      <img src={imgSrc} className={classes.logo} alt="title" />
      {title}
    </div>
  )
}

export default withStyles(styles)(LogoAndText)
