import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Select,
  FormControl,
} from "@material-ui/core"

import { expandBreakpoint } from "../../styles"
import { navTextSize } from "../styles"

const styles = ({
  breakpoints: { up },
  palette: {
    text: { primary },
  },
}: Theme) => {
  const expandBreakpointUp = up(expandBreakpoint)
  const selectPaddingY = 0
  return createStyles({
    root: {
      marginTop: "3rem",
      flexShrink: 0,
      width: "7.5rem",
      [expandBreakpointUp]: {
        margin: 0,
        marginLeft: "2rem",
      },
      "& + &": {
        marginTop: "1rem",
        [expandBreakpointUp]: {
          marginLeft: 0,
          marginTop: 0,
        },
      },
    },
    select: {
      fontSize: navTextSize,
      paddingTop: selectPaddingY,
      paddingBottom: selectPaddingY,
      lineHeight: "2rem",
    },
    icon: {
      color: primary,
    },
  })
}

interface Props<Value> extends WithStyles<typeof styles> {
  children: React.ReactNode
  label: string
  value: string
  setValue: React.Dispatch<Value>
  id: string
}

export default function getOptions<Value>() {
  const Options = ({
    classes,
    children,
    id,
    value,
    setValue,
  }: Props<Value>) => {
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setValue(event.target.value as Value)
    }

    const labelId: string = `${id}-label`

    const { root, ...otherClasses } = classes

    return (
      <FormControl className={root}>
        <Select
          labelId={labelId}
          id={id}
          value={value}
          onChange={handleChange}
          classes={otherClasses}
          disableUnderline
        >
          {children}
        </Select>
      </FormControl>
    )
  }

  return withStyles(styles)(Options)
}
