import { withStyles, createStyles } from "@material-ui/core"

import Button from "../../../../../Links/Item/Button"
import { navTextSize } from "../../styles"

const styles = () => {
  return createStyles({
    root: {
      fontSize: navTextSize,
    },
  })
}

export default withStyles(styles)(Button)
