import React from "react"
import {
  Box,
  withStyles,
  WithStyles,
  createStyles,
  BoxProps,
  Theme,
} from "@material-ui/core"

import { getMarginX, getPaddingX } from "../utils/styles"

export const containerPadding = {
  xs: "1.5rem",
  sm: "2.5625rem",
  lg: "7.0625rem",
}

export const containerMaxWidth: string = `95.25rem`

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      ...getPaddingX(containerPadding.xs),
      ...getMarginX("auto"),
      maxWidth: containerMaxWidth,
      [up("sm")]: {
        ...getPaddingX(containerPadding.sm),
      },
      [up("lg")]: {
        ...getPaddingX(containerPadding.lg),
      },
      [up("xl")]: {
        maxWidth: `calc(${containerMaxWidth} + ${containerPadding.lg} * 2)`,
      },
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<BoxProps, "classes" | "children"> {
  children: React.ReactNode
}

const PageContainer = ({ classes, className = "", ...other }: Props) => {
  const combinedClassName: string = `${classes.root} ${className}`
  return <Box className={combinedClassName} {...other} />
}

export default withStyles(styles)(PageContainer)
