import React, { useEffect } from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import loadable from "@loadable/component"

import Nav from "./Nav"
import MiddleBackground from "../MiddleBackground"
import MainBackground from "./Background"
import { useContext } from "react"
import { ColorSchemeContext } from "../../contexts/ColorScheme"
import links from "../../constants/links"
import { useGlobalLanguageText } from "../../utils/language"
import ScrollToTop from "./ScrollToTop"
import { useRedirectToPageWithLocalePrefix } from "../../utils/redirect"

const Footer = loadable(() => import("./Footer"))

const styles = () => {
  return createStyles({
    page: {
      overflow: "hidden",
      position: "relative",
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
  footer?: React.ReactNode
  nav?: React.ReactNode
}

const Layout = ({
  classes,
  children,
  footer = <Footer />,
  nav = <Nav />,
}: Props) => {
  const { colorScheme } = useContext(ColorSchemeContext)!
  useRedirectToPageWithLocalePrefix()

  const getThemeColor = (): string => {
    switch (colorScheme) {
      case "morning":
        return "#D2D5E2"
      case "day":
        return "#4486D0"
      case "evening":
        return "#662062"
      default:
        return "#011317"
    }
  }

  const themeColor: string = getThemeColor()

  const {
    cookieBanner: { start, end, privacyStatement, accept, decline },
  } = useGlobalLanguageText()

  const registerServiceWorker = (): void => {
    if (
      "serviceWorker" in navigator &&
      process.env.NODE_ENV === "development"
    ) {
      navigator.serviceWorker.register("/sw.js")
    }
  }

  useEffect(() => {
    registerServiceWorker()
  }, [])

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={themeColor}></meta>
      </Helmet>
      <div className={classes.page}>
        {nav}
        <main>{children}</main>
        <MainBackground />
        <MiddleBackground />
        {footer}
        <CookieConsent
          location="bottom"
          buttonText={accept}
          declineButtonText={decline}
          buttonStyle={{ background: `white`, fontSize: `1rem` }}
          cookieName="gatsby-gdpr-google-analytics"
        >
          {start}{" "}
          <Link id="privacy" to={links.privacy} style={{ color: `white` }}>
            {privacyStatement}
          </Link>{" "}
          {end}
        </CookieConsent>
        <ScrollToTop />
      </div>
    </>
  )
}

export default withStyles(styles)(Layout)
