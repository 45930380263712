import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core"

import BaseButton, { BaseProps as ButtonBaseProps } from "./Button"

const styles = () => {
  return createStyles({
    root: {
      listStyle: "none",
    },
  })
}

export interface BaseProps extends ButtonBaseProps {}

interface Props extends WithStyles<typeof styles>, BaseProps {
  Button: typeof BaseButton
}

function Item({ classes, Button, ...other }: Props) {
  return (
    <li className={classes.root}>
      <Button {...other} />
    </li>
  )
}

export default withStyles(styles)(Item)
