import React from "react"
import {
  createStyles,
  WithStyles,
  withStyles,
  Hidden,
  Theme,
} from "@material-ui/core"

const styles = ({
  palette: {
    text: { primary },
  },
}: Theme) => {
  return createStyles({
    root: {
      paddingRight: 0,
      "& .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before": {
        backgroundColor: `${primary} !important`,
        width: "2rem",
      },
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<
      React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      "className" | "children"
    > {
  className?: string
}

function ToggleNav({ className = "", classes, ...other }: Props) {
  return (
    <Hidden mdUp>
      <button
        {...other}
        className={`hamburger ${classes.root} ${className}`}
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </Hidden>
  )
}

export default withStyles(styles)(ToggleNav)
