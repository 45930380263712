import React from "react"
import { createStyles, WithStyles, withStyles, Hidden } from "@material-ui/core"

import { openDrawer } from "../types"
import HamburgerButton from "../HamburgerButton"

const styles = () => {
  return createStyles({})
}

interface Props extends WithStyles<typeof styles> {
  openDrawer: openDrawer
}

function ToggleNav({ openDrawer }: Props) {
  return (
    <Hidden mdUp>
      <HamburgerButton
        onClick={openDrawer}
        data-toggle="collapse"
        data-target="#mainNav"
        aria-controls="mainNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      />
    </Hidden>
  )
}

export default withStyles(styles)(ToggleNav)
