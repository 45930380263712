export const getPaddingX = (amount: string) => ({
  paddingLeft: amount,
  paddingRight: amount,
})

export const getPaddingY = (amount: string) => ({
  paddingTop: amount,
  paddingBottom: amount,
})

export const getMarginX = (amount: string) => ({
  marginLeft: amount,
  marginRight: amount,
})

interface FlexItemMarginInfo {
  index: number
}

export interface CardMarginInfo extends FlexItemMarginInfo {
  postsAmount: number
  index: number
}

type Margin = string
type Columns = number

export const getMarginBottom = <Props extends CardMarginInfo>(
  margin: Margin,
  postsPerRow: Columns
) => (props: Props): string | number => {
  const bottomPosts: number = props.postsAmount % postsPerRow || postsPerRow
  const firstBottomPostIndex: number = props.postsAmount - bottomPosts
  const isBottomPost: boolean = props.index >= firstBottomPostIndex
  if (isBottomPost) {
    return 0
  }
  return margin
}

export const getMarginBottomCreator = <Props extends CardMarginInfo>() => (
  margin: string,
  postsPerRow: number
) => getMarginBottom<Props>(margin, postsPerRow)

export const getFlexItemMarginLeft = <Props extends FlexItemMarginInfo>(
  margin: Margin,
  columns: Columns
) => (props: Props): string | number => {
  const isFirstInRow: boolean = props.index === 0 || props.index % columns === 0
  if (isFirstInRow) {
    return 0
  }
  return margin
}

export const getSizeStyles = (size: string) => {
  return {
    width: size,
    height: size,
  }
}
