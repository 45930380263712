import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

const styles = () => {
  return createStyles({
    root: {
      zIndex: -2,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  src: string
  alt: string
  className?: string
}

const PageBackground = ({ classes, className = "", ...other }: Props) => {
  const combinedClassName = `${classes.root} ${className}`

  return <img className={combinedClassName} {...other} />
}

export default withStyles(styles)(PageBackground)
