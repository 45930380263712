import React from "react"
import { createStyles, withStyles, WithStyles } from "@material-ui/core"

import BaseItem, { BaseProps } from "./Item"
import { NavLink } from "../../content/pages/global"

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 0,
      margin: 0,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  Item?: React.FunctionComponent<BaseProps>
  links: NavLink[]
}

const BaseLinks = ({ classes, links, Item }: Props) => {
  const renderItem = (link: NavLink): JSX.Element => {
    return (
      <Item key={link.href} href={link.href} openInNewTab={link.openInNewTab}>
        {link.name}
      </Item>
    )
  }

  const renderedLinks: JSX.Element[] = links.map(renderItem)

  return <ul className={classes.root}>{renderedLinks}</ul>
}

export default withStyles(styles)(BaseLinks)
