import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  TypographyProps,
  Typography,
  Hidden,
} from "@material-ui/core"

const styles = () => {
  return createStyles({
    root: {},
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<TypographyProps, "children" | "classes"> {
  seoTitle?: React.ReactNode
  component?: React.ElementType<any>
}

const Title = ({ classes, seoTitle = null, component = "p" }: Props) => {
  return (
    <Typography
      className={classes.root}
      color="textPrimary"
      component={component}
    >
      {seoTitle && (
        <Hidden implementation="css" xsUp>
          {seoTitle}
        </Hidden>
      )}
    </Typography>
  )
}

export default withStyles(styles)(Title)
