import React, { useContext } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import { ColorSchemeContext } from "../../contexts/ColorScheme"
import morning from "./bg_middle_morning.svg"
import day from "./bg_middle_day.svg"
import evening from "./bg_middle_evening.svg"
import night from "./bg_middle_night.svg"
import PageBackground from "../PageBackground"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      position: "absolute",
      top: "154.9375rem",
      height: "115.625rem",
      left: "50%",
      transform: "translateX(-50%)",
      [up("xl")]: {
        height: "auto",
        width: "100%",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const MiddleBackground = ({ classes }: Props) => {
  const { colorScheme } = useContext(ColorSchemeContext)!

  const getBackgroundSrc = (): string => {
    switch (colorScheme) {
      case "morning":
        return morning
      case "day":
        return day
      case "evening":
        return evening
      default:
        return night
    }
  }

  const src: string = getBackgroundSrc()

  return (
    <PageBackground
      src={src}
      alt="Middle background"
      className={classes.root}
    />
  )
}

export default withStyles(styles)(MiddleBackground)
