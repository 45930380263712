import React from "react"
import {
  Drawer as BaseDrawer,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

import Toolbar from "./Toolbar"
import { closeDrawer, isDrawerOpen } from "../types"
import Content from "../Content"

interface Body extends CSSProperties {
  background: string
}

const styles = ({ overrides }: Theme) => {
  const background = (overrides.MuiCssBaseline["@global"].body as Body)
    .background
  return createStyles({
    paper: {
      padding: "1rem 2rem",
      background,
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  closeDrawer: closeDrawer
  isDrawerOpen: isDrawerOpen
}

const Drawer = ({ classes, closeDrawer, isDrawerOpen }: Props) => {
  return (
    <BaseDrawer
      classes={classes}
      onClose={closeDrawer}
      open={isDrawerOpen}
      variant="temporary"
      anchor="right"
    >
      <Toolbar closeDrawer={closeDrawer} />
      <Content />
    </BaseDrawer>
  )
}

export default withStyles(styles)(Drawer)
