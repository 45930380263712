import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Theme,
} from "@material-ui/core"

import links from "../../../../constants/links"
import { expandBreakpoint } from "../styles"
import { getNavigateOnClickHandler } from "../../../../utils/buttons"
import { useGlobalLanguageText } from "../../../../utils/language"

const styles = ({ breakpoints: { up } }: Theme) => {
  const expandBreakpointUp = up(expandBreakpoint)
  return createStyles({
    root: {
      marginTop: "2rem",
      [expandBreakpointUp]: {
        marginLeft: "3.0625rem",
        marginTop: 0,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const DownloadButton = ({ classes }: Props) => {
  const handleClick = getNavigateOnClickHandler(links.download)

  const {
    nav: { download },
  } = useGlobalLanguageText()

  return (
    <Button
      href={links.download}
      classes={classes}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      {download}
    </Button>
  )
}

export default withStyles(styles)(DownloadButton)
