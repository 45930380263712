import React, { useState } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  IconButton,
} from "@material-ui/core"
import { useEffect } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

import ArrowTop from "./ArrowTop"
import { sectionIds } from "../../../constants/ids"

const styles = () => {
  const width: string = "3rem"
  const screenOffset = "1rem"
  const backgroundColor = "white"
  return createStyles({
    root: {
      width,
      height: width,
      borderRadius: "50%",
      position: "fixed",
      bottom: screenOffset,
      right: screenOffset,
      backgroundColor,
      zIndex: 1,
      "&:hover, &:focus": {
        backgroundColor,
      },
    },
    icon: {
      width: "2rem",
      color: "black",
      zIndex: 2,
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const ScrollToTop = ({ classes }: Props) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false)

  const getHeight = (): number => {
    const { body } = document
    return Math.max(body.offsetHeight, body.scrollHeight)
  }

  const handleScroll = (): void => {
    const height: number = getHeight()
    const scrolledMoreThanHalf: boolean = window.pageYOffset > height / 2
    if (!shouldShow && scrolledMoreThanHalf) {
      setShouldShow(true)
    } else if (shouldShow && !scrolledMoreThanHalf) {
      setShouldShow(false)
    }
  }

  useEffect(() => {
    window.onscroll = handleScroll
  }, [shouldShow])

  const handleClick = (): void => {
    scrollTo(`#${sectionIds.nav}`)
  }

  if (!shouldShow) return null

  return (
    <IconButton className={classes.root} onClick={handleClick}>
      <ArrowTop className={classes.icon} />
    </IconButton>
  )
}

export default withStyles(styles)(ScrollToTop)
