import React from "react"
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core"

import { getExpandBreakpoint } from "../styles"
import Links from "./Links"
import Languages from "./Languages"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import ColorSchemes from "./ColorSchemes"
import DownloadButton from "./DownloadButton"

const styles = (theme: Theme) => {
  const expandBreakpoint: string = getExpandBreakpoint(theme)

  const linksContainer: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    [expandBreakpoint]: {
      flexDirection: "row",
      alignItems: "center",
    },
  }

  return createStyles({
    root: {
      ...linksContainer,
      justifyContent: "flex-end",
      width: "100%",
    },
    content: {
      ...linksContainer,
    },
    linksAndLanguages: {
      ...linksContainer,
      [expandBreakpoint]: {
        flexDirection: "row",
        alignItems: "flex-end",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
}

const Content = ({ classes, children = null }: Props) => {
  return (
    <div className={classes.root}>
      {children}
      <div className={classes.content}>
        <div className={classes.linksAndLanguages}>
          <Links />
          <Languages />
          <ColorSchemes />
        </div>
        <DownloadButton />
      </div>
    </div>
  )
}

export default withStyles(styles)(Content)
