import React from "react"
import {
  Button,
  withStyles,
  WithStyles,
  createStyles,
  ButtonProps,
} from "@material-ui/core"

import { getNavigateOnClickHandler } from "../../../utils/buttons"
import { NavLink } from "../../../content/pages/global"

const styles = () => {
  return createStyles({
    root: {
      lineHeight: "2rem",
      fontWeight: 300,
      padding: 0,
    },
  })
}

export interface BaseProps {
  href: string
  children: React.ReactNode
  openInNewTab?: NavLink["openInNewTab"]
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const NavButton = ({ href, openInNewTab, ...other }: Props) => {
  const isPageLink: boolean = href[0] === "/"

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ): void => {
    if (openInNewTab) {
      event.preventDefault()
      window.open(href, "_blank").focus()
    } else if (isPageLink) {
      return getNavigateOnClickHandler(href)(event)
    }
  }

  return <Button href={href} onClick={handleClick} {...other} />
}

export default withStyles(styles)(NavButton)
