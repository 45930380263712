import { createStyles, Theme, makeStyles } from "@material-ui/core"
import React from "react"

import BaseItem, { BaseProps } from "../../../../../Links/Item"
import Button from "./Button"

const useStyles = makeStyles(({ breakpoints: { up } }: Theme) => {
  const md = up("md")
  return createStyles({
    root: {
      flexShrink: 0,
      "& + &": {
        marginTop: "1rem",
        [md]: {
          marginTop: 0,
          marginLeft: "2.875rem",
        },
      },
    },
  })
})

interface Props extends BaseProps {}

const Item = (props: Props) => {
  const classes = useStyles(props)
  return <BaseItem Button={Button} classes={classes} {...props} />
}

export default Item
