import React, { useState } from "react"
import {
  AppBar,
  Toolbar,
  createStyles,
  WithStyles,
  withStyles,
  Theme,
} from "@material-ui/core"

import TogglerBtn from "./ToggleNav"
import { isDrawerOpen, openDrawer, closeDrawer } from "./types"
import ExpandedContent from "./ExpandedContent"
import Drawer from "./Drawer"
import PageContainer from "../../PageContainer"
import { sectionIds } from "../../../constants/ids"
import { navDimensions } from "../../../constants/heights"

const styles = (theme: Theme) => {
  const backgroundColor: string = "transparent"
  const minHeight: number = 0
  const paddingX: number = 0
  return createStyles({
    root: {
      padding: `2rem ${paddingX} 0`,
      backgroundColor,
      boxShadow: "none",
      top: 0,
      minHeight,
      marginBottom: navDimensions.marginBottom,
      [theme.breakpoints.up("md")]: {
        paddingTop: "3.375rem",
      },
    },
    toolbar: {
      backgroundColor,
      minHeight,
      paddingLeft: paddingX,
      paddingRight: paddingX,
    },
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "flex-end",
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  expandedContent?: React.ReactNode
}

const Nav = ({ classes, expandedContent = <ExpandedContent /> }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<isDrawerOpen>(false)

  const openDrawer: openDrawer = () => setIsDrawerOpen(true)

  const closeDrawer: closeDrawer = () => setIsDrawerOpen(false)

  return (
    <>
      <AppBar className={classes.root} position="sticky" id={sectionIds.nav}>
        <Toolbar className={classes.toolbar}>
          <PageContainer className={classes.container}>
            {expandedContent}
            <TogglerBtn openDrawer={openDrawer} />
          </PageContainer>
        </Toolbar>
      </AppBar>
      <Drawer closeDrawer={closeDrawer} isDrawerOpen={isDrawerOpen} />
    </>
  )
}

export default withStyles(styles)(Nav)
