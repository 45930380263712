import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import BaseLinks from "../../../../Links"
import Item from "./Item"
import { getExpandBreakpoint } from "../../styles"
import { useGlobalLanguageText } from "../../../../../utils/language"

const styles = (theme: Theme) => {
  const expandBreakpoint: string = getExpandBreakpoint(theme)
  return createStyles({
    root: {
      [expandBreakpoint]: {
        flexDirection: "row",
        marginRight: "2rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Links = (props: Props) => {
  const {
    nav: { links },
  } = useGlobalLanguageText()

  return <BaseLinks {...props} Item={Item} links={links} />
}

export default withStyles(styles)(Links)
