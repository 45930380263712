import { withStyles, createStyles, MenuItem } from "@material-ui/core"

const styles = () => {
  return createStyles({
    root: {
      color: "black",
    },
  })
}

export default withStyles(styles)(MenuItem)
