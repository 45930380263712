import React from "react"
import { useContext } from "react"

import {
  ColorScheme,
  ColorSchemeContext,
} from "../../../../../contexts/ColorScheme"
import { useGlobalLanguageText } from "../../../../../utils/language"
import getOptions from "../Options"
import Item from "../Options/Item"

const Options = getOptions<ColorScheme>()

export default function ColorSchemes() {
  const { colorScheme, setColorScheme } = useContext(ColorSchemeContext)!
  const {
    colors: { morning, day, evening, night },
  } = useGlobalLanguageText()!
  return (
    <Options
      label="Color"
      id="color-scheme"
      // Because select always wants a string value, but the "gray" option shouldn't be shown
      value={colorScheme === "gray" ? "" : colorScheme}
      setValue={setColorScheme}
    >
      <Item value="morning">{morning}</Item>
      <Item value="day">{day}</Item>
      <Item value="evening">{evening}</Item>
      <Item value="night">{night}</Item>
    </Options>
  )
}
