import { withStyles, createStyles, Theme } from "@material-ui/core"

import Content from "./Content"
import { getExpandBreakpoint } from "./styles"

const styles = (theme: Theme) => {
  const expandBreakpoint: string = getExpandBreakpoint(theme)
  return createStyles({
    root: {
      display: "none",
      [expandBreakpoint]: {
        display: "flex",
      },
    },
  })
}

export default withStyles(styles)(Content)
