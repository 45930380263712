import { useContext } from "react"
import { ColorScheme, ColorSchemeContext } from "../contexts/ColorScheme"

export const getIfColorIsDark = (color: ColorScheme): boolean => {
  const darkColors: ColorScheme[] = ["evening", "night", "gray"]
  return darkColors.includes(color)
}

export const useIsDarkMode = (): boolean => {
  const { colorScheme } = useContext(ColorSchemeContext)!
  return getIfColorIsDark(colorScheme)
}
