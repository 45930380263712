import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Item from "../Options/Item"
import getOptions from "../Options"
import { QueryWithNodes } from "../../../../../types/queries"
import Language from "../../../../../types/Language"
import { SelectedLanguageContext } from "../../../../../contexts/SelectedLanguage"

interface AllStrapiLanguage extends QueryWithNodes<Language> {}

interface Query {
  allStrapiLanguage: AllStrapiLanguage
}

const Options = getOptions<Language["id"]>()

const Languages = () => {
  const {
    allStrapiLanguage: { nodes: languages },
  }: Query = useStaticQuery(graphql`
    {
      allStrapiLanguage {
        nodes {
          id: strapiId
          name
          abbreviation
        }
      }
    }
  `)

  const renderItem = (item: Language): JSX.Element => {
    return (
      <Item value={item.id} key={item.id}>
        {item.name}
      </Item>
    )
  }

  const renderedLanguages: JSX.Element[] = languages.map(renderItem)

  const { setLanguage, language } = useContext(SelectedLanguageContext)!

  return (
    <Options
      label="Language"
      value={language}
      id="language"
      setValue={setLanguage}
    >
      {renderedLanguages}
    </Options>
  )
}

export default Languages
